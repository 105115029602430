import React from "react";


const Page404 = () => {
    return (
        <div className="text-center mt-5 pt-5">
            <h1>404</h1><p>Page not found</p>         
        </div>
    )
}


export default Page404;