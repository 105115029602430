import React from "react";
import HomeData from "../components/HomeData";


const Home = () => {
    return (
        <div>
            <HomeData />
        </div>
    )
}


export default Home;